import React from 'react';
import {createRoot} from 'react-dom/client';
import {LoginWrapper} from '~/LoginModal/LoginWrapper';
import {getURLParameter} from '~/common/utils';

const onSignupSuccess = () => {
  const next = getURLParameter('next');
  if (next) {
    window.location.href = next;
  } else {
    window.location.href = '/';
  }
};
const onLoginSuccess = () => {
  onSignupSuccess();
};
const onCloser = () => {};

export const StandaloneLogin = () => (
  <div className="meganav-login-modal__wrapper">
    <LoginWrapper
      onLoginSuccess={onLoginSuccess}
      onCloser={onCloser}
      onSignupSuccess={onSignupSuccess}
      isModal={false}
    />
  </div>
);

const container = document.getElementById('loginRoot');

if (container) {
  const root = createRoot(container);
  root.render(<StandaloneLogin />);
}
